import React from 'react';
import { useTranslation, Link } from '@3nvi/gatsby-theme-intl';

import StaticPageLayout from '../components/staticPageLayout';
import SEO from '../components/seo';
import styled from 'styled-components';

const Content = styled.div`
  max-width: 780px;
  h2 {
    font-size: 28px;
    margin-top: 20px;
  }
  li,
  p {
    margin-bottom: 0;
  }
  ol {
    list-style-type: none;
    margin: 0;
  }
`;

const TomaszTobysPage = () => {
  const { t } = useTranslation();
  return (
    <StaticPageLayout title="Tomasz Tobys">
      <SEO title="Tomasz Tobys" />
      <Content>
        <h2>Founder, główny projektant, menadżer</h2>
        <p>
          Absolwent Wydziału Nauk Społecznych Uniwersytetu Śląskiego, Wydziału Projektowego Akademii
          Sztuk Pięknych w Katowicach oraz Instytutu Kultury Uniwersytetu Jagiellońskiego. Swoją
          pracę projektowo-artystyczną rozpoczął w 2007 roku.
        </p>
        <p>
          Założyciel portalu FONT nie czcionka! oraz studia projektowego studio FNC. Pomysłodawca
          portalu „Grafik płakał jak projektował” oraz strony na Facebooku o tym samym tytule, która
          stała się najpopularniejszym fanpage’em o tematyce projektowej w Polsce (ponad 190 tys.
          fanów), w 2019 roku rozpoczął pracę nad starup-em teapp.
        </p>
        <p>
          Projektant zaangażowany społecznie. Uważa, że twórcy i artyści mają duży wpływ na to, jak
          będzie wyglądać przyszłość. Pomimo fascynacji motoryzacją, po mieście jeździ na rowerze.
        </p>
      </Content>
    </StaticPageLayout>
  );
};

export default TomaszTobysPage;
